const routerApi = {
  reloadPage: () => {
    Turbo.visit(window.location)
  }
}
export default {
  install (app) {
    app.config.globalProperties.$router = routerApi
  }
}
