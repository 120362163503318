import Rails from '@rails/ujs'
import '@hotwired/turbo-rails'
import { createApp } from 'vue'
import turboVueAdapter from './turbo/turbo-vue-adapter'
import * as Routes from '../js-routes.js.erb'
import initSentry from './sentry'

require('./handle-errors')
require('./i18n')

const createVueApp = (ContextRegister) => {
  const app = createApp()

  ContextRegister(app)
  app.mount('#application')
  initSentry(app)
  window.vueApp = app
  return app
}

export default {
  init (ContextRegister) {
    Rails.start()
    window.Routes = Routes
    const app = createVueApp(ContextRegister)
    turboVueAdapter(app, createVueApp.bind(null, ContextRegister))
  }
}
