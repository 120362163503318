<template>
  <teleport to="body">
    <transition
      name="fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
    >
      <div
        :class="classList"
        :style="{width, height}"
        v-if="showDialog"
        v-bind="$attrs"
        :id="name"
      >
        <div class="body">
          <div class="close-area-wrapper" @click="closeModal">
            <i class="material-icons">close</i>
          </div>

          <slot />
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
  import isMobile from 'is-mobile'

  export default {
    props: {
      name: { type: String, default: '' },
      beforeOpen: { type: Function, default: null },
      beforeClose: { type: Function, default: null },
      autoOpen: { type: Boolean, default: false },
      dynamic: { type: Boolean, default: false },
      openOnlyOne: { type: Boolean, default: false },
      width: { type: String, default: '600px' },
      height: { type: String, default: '' },
      classes: { type: String, default: null },
    },

    data () {
      return {
        openDialogs: null,
        showDialog: false,
        callbackAfterClose: null
      }
    },

    provide () {
      return { appModal: this }
    },

    computed: {
      isMobile () { return isMobile() },

      dialog () {
        const dialogs = [...document.querySelectorAll(`.app-modal#${this.name}`)]
        const actualDialog = dialogs.pop()
        this.clearOldDialogs(dialogs)

        return actualDialog
      },

      classList () {
        let classesArray = ['app-modal', this.classes]
        if (this.dynamic) { classesArray.push('dynamic') }
        return classesArray
      }
    },

    mounted () {
      this.$modal.addModal(this)
      if (this.autoOpen) { this.$modal.show(this.name) }
    },

    methods: {
      showModal (data) {
        this.showDialog = true
        this.$nextTick(() => {
          if (this.openOnlyOne) { this.hideAllOtherOpenDialogs() }
          if (this.beforeOpen) { this.beforeOpen(data) }
        })
      },

      closeModal (callbackAfterClose) {
        this.showDialog = false
        if (this.beforeClose) { this.beforeClose() }
        if (this.dynamic) { this.dialog.remove() }
        this.callbackAfterClose = callbackAfterClose
      },

      clearOldDialogs (oldDialogs) {
        oldDialogs.forEach((dialog) => { dialog.remove() })
      },

      hideAllOtherOpenDialogs () {
        this.openDialogs = document.querySelectorAll(`.app-modal:not(#${this.name})`)
        this.openDialogs.forEach((dialog) => {
          dialog.style.display = 'none'
        })
      },

      showAllOtherOpenDialogs () {
        this.openDialogs.forEach((dialog) => {
          this.$classFor('body', 'not-scrolled-with-modal', true)
          dialog.style.display = 'block'
        })
      },

      beforeEnter () {
        this.$classFor('body', 'not-scrolled-with-modal', true)
      },

      afterLeave () {
        this.$classFor('body', 'not-scrolled-with-modal', false)
        if (this.openOnlyOne) { this.showAllOtherOpenDialogs() }
        if (typeof this.callbackAfterClose == 'function') { this.callbackAfterClose() }
      }
    }
  }
</script>

<style lang="scss">
  @import '@variables/mobile-dimensions';

  .app-modal {
    display: block;
    height: fit-content;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 10000;

    &::after {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.65) !important;
    }

    &.dynamic {
      .body {
        padding: 0;
      }
    }

    .modal-title {
      margin-bottom: 15px !important;
    }

    @include mobile {
      width: 100% !important;
      left: 0 !important;
      border-radius: unset !important;
      max-height: 100%;
      max-width: 100%;
    }

    &::backdrop {
      background: rgba(0, 0, 0, 0.65) !important;
    }
  }

  .app-modal {
    .body {
      position: relative;
      max-height: 90vh;
      border-radius: 10px;
      padding: var(--app-modal-body-padding, 40px 30px 30px);
      overflow: auto;
      box-sizing: border-box;
      background-color: var(--white);
      z-index: 100;

      @include mobile {
        position: relative;
        padding: 50px 20px 100px;
        max-width: $mobile-content-width;
        min-height: 100vh;
        -webkit-overflow-scrolling: touch;
        margin: 0 auto;
        z-index: 99;
      }

      .close-area-wrapper {
        height: 50px;
        width: 50px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 5px;
        right: 5px;
        z-index: 199;
        cursor: pointer;
      }
    }
  }

  .simple-signature-modal .body {
    @include mobile {
      flex-direction: column;
      display: flex;
      justify-content: center;
    }
  }
</style>

<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .2s;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
