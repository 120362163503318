<template>
  <div>
    <div class="wrapper" v-if="files && files.length">
      <div class="disclaimer">
        <app-checkbox
          name="accepted"
          :label="disclaimer"
          class="last-bottom"
          v-model="accepted"
        />
      </div>
      <div class="documents">
        <div v-for="(file, index) in files" :key="index">
          <div class="document">
            <input type="hidden" name="legal_documents[]" :value="file.id">

            <a class="link link-default body-sm-txt" target="_blank" :href="file.link">
              {{ file.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <input
      type="hidden"
      name="accepted"
      value="true"
      v-else
    >
    <slot :accepted="accepted" />
  </div>
</template>

<script>
  export default {
    props: {
      disclaimer: { type: String, default: null },
      files: { type: Array, required: true }
    },
    data () {
      return {
        accepted: this.files.length === 0
      }
    }
  }
</script>

<style lang='scss' scoped>
  .wrapper {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
  }

  .documents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    .document {
      width: 100%;
      word-break: break-word;
      margin-left: 35px;
      @include mobile { width: calc(100% - 20px); }
    }
  }

  .disclaimer {
    margin-bottom: 10px;
    font-weight: 500;
  }
</style>
