import hashSum from 'hash-sum'
import appModal from '@components/modals/main_template/app-modal.vue'
import mount from '../system/runtime-mount'

let modals = {}

const modalApi = {
  addModal: (modal) => {
    modals[modal.name] = modal
  },

  show: (name, data = null) => {
    modals[name].showModal(data)
  },

  showDynamic: (component, componentProps, modalProps, modalEvents) => {
    const dynamicModalName = modalProps?.name || `modal_${hashSum(component.__file)}`

    mount(
      appModal,
      {
        props: {
          dynamic: true, name: dynamicModalName, ...modalProps, ...modalEvents
        }
      },
      [[component, componentProps]]
    )

    modals[dynamicModalName].showModal()
  },

  hide: (modal, callback) => {
    const modalName = typeof modal === 'string' ? modal : modal.$.provides.appModal.name

    modals[modalName]?.closeModal(callback)
  }
}

export default modalApi
