export default {
  beforeMount (el, { value }, vnode) {
    const target = value
    target.href = el.href

    el.onclick = (event) => {
      event.stopPropagation()
      event.preventDefault()

      vnode.ctx.appContext.config.globalProperties.$modal.show('confirmDialog', target)
    }
  }
}
