import * as Sentry from '@sentry/browser'

export default function initSentry (app) {
  if (gon.sentry?.SENTRY_ENABLE) {
    if (process.env.NODE_ENV === 'development') { console.warn('Sentry init') }

    Sentry.init({
      app,
      sendClientReports: false,
      dsn: gon.sentry.SENTRY_DSN,
      debug: gon.sentry.SENTRY_DEBUG
    })
  }
}
